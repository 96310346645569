let routes = [

    {
      path: "/user/",
      props:true,
      component: () => import("@/views/user/auth/main.vue"),

      children: [
        {
          path: "login",
          name: "user_login",
          props:true,
          component: () => import("@/views/user/auth/login.vue"),
          meta: {
          title: 'Login to Your Dashboard'
          }
        },

        {
          path: "sign-up",
          name: "user_register",
          props:true,
          component: () => import("@/views/user/auth/register.vue"),
          meta: {
          title: 'Login to Your Dashboard'
          }
        },

        {
          path: "forgot-password",
          name: "user_forgot_password",
          props:true,
          component: () => import("@/views/user/auth/forgot_password.vue"),
          meta: {
          title: 'Login to Your Dashboard'
          }
        },

      ]
  },


    {
        path: "/auth/",
        props:true,
        component: () => import("@/views/auth/main.vue"),

        children: [
          {
            path: "login",
            name: "login",
            props:true,
            component: () => import("@/views/auth/login.vue"),
            meta: {
             title: 'Login to Your Dashboard'
            }
          },

          {
            path: "sign-up",
            name: "register",
            props:true,
            component: () => import("@/views/auth/register.vue"),
            meta: {
             title: 'Login to Your Dashboard'
            }
          },

          {
            path: "forgot-password",
            name: "forgot_pwd",
            props:true,
            component: () => import("@/views/auth/forgot_pwd.vue"),
            meta: {
             title: 'Login to Your Dashboard'
            }
          },

          {
            path: "verify_device",
            name: "verify_device",
            props:true,
            component: () => import("@/views/auth/verify_device.vue"),
            meta: {
             title: 'Verify Your Device'
            }
          },

          {
            path: "verify_account/:number",
            name: "verify_account",
            props:true,
            component: () => import("@/views/auth/verify_account.vue"),
            meta: {
             title: 'Verify Account'
            }
          },

 
        ]
    },

]
export default routes;