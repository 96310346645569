export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        districts :'', 
        district :'', 
    },
    
    getters : {
        districts: (state )=> state.districts,
        district: (state )=> state.district,
    },


    mutations : { 
        setdistricts :(state,districts ) => (state.districts = districts), 
        setdistrict :(state,district ) => (state.district = district), 
    },

    actions : {

        async create_district( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_district')
            return new Promise((resolve, reject) => {
            axios.post("/setup/districts/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdistricts', response.data.data)
                toast.success("District Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },


        async get_districts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_districts')
            return new Promise((resolve, reject) => {
            axios.post("/setup/districts?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdistricts', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_district( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_district' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/setup/updates/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdistrict', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_district( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_district' + id)
            return new Promise((resolve, reject) => {
            axios.post("/setup/districts/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdistricts', response.data.data)
                toast.success("District Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_district_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_district_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/setup/districts/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setdistricts', response.data.data)
                        if (data.status == 1) {
                            toast.success("district Activated Successfully")
                        } else {
                            toast.success("district Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                comp_play_error_file(error.response.data.msg);
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                                comp_play_error_file(error.response.data.message);
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_district( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_district' + id)
            return new Promise((resolve, reject) => {
            axios.post("/setup/districts/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdistricts', response.data.data)
                comp_play_delete_file('district Deleted Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


            //permissions
        async add_district_permission( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'add_district_permission')
            return new Promise((resolve, reject) => {
            axios.post("/setup/districts/add_permission", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdistricts', response.data.data)
                toast.success("district Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },
    }


    
}