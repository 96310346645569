<template>
      <router-view></router-view>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default{
    methods:{
        ...mapActions(['getuserlocation','getuseripaddress', 'get_system_settings','get_user_device_details']),

    },
    mounted(){
        this.get_user_device_details()
    },

    created(){
        this.getuseripaddress().then(()=>{
            this.getuserlocation();
        });
 
    }
}
</script>

