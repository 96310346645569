import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import systemupdates from './modules/systemupdates';
import systemsettings from './modules/systemsettings';
//settings
import institutions  from './modules/institutions'
import loans  from './modules/loans'

import districts  from './modules/districts'
import subcounties  from './modules/subcounties'
import loancategories  from './modules/loancategories'
import institutioncategories  from './modules/institutioncategories'


//user
import user_global_module  from './user_modules/user_global_module'
import user_institutions  from './user_modules/user_insititutions'
import user_loans  from './user_modules/user_loans'
import user_comparisions  from './user_modules/user_comparisions'

import trashbin from './modules/trashbin';
export default createStore({
  modules: {
    auth, 
    utils,
    systemupdates,
    systemsettings,

    institutions,
    loans,
    districts,
    subcounties,
    loancategories,
    institutioncategories,
    
    trashbin,

    //user
    user_institutions,
    user_loans,
    user_comparisions,
    user_global_module
  },
    
  plugins: [createPersistedState(
    {
      key : 'umraloanssystem.deepcodegroup.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
