export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        loancategories :'', 
        loancategory :'', 
    },
    
    getters : {
        loancategories: (state )=> state.loancategories,
        loancategory: (state )=> state.loancategory,
    },


    mutations : { 
        setloancategories :(state,loancategories ) => (state.loancategories = loancategories), 
        setloancategory :(state,loancategory ) => (state.loancategory = loancategory), 
    },

    actions : {

        async create_loancategory( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_loancategory')
            return new Promise((resolve, reject) => {
            axios.post("/setup/loancategories/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setloancategories', response.data.data)
                toast.success("loancategory Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },


        async get_loancategories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_loancategories')
            return new Promise((resolve, reject) => {
            axios.post("/setup/loancategories?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setloancategories', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_loancategory( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_loancategory' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/setup/updates/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setloancategory', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_loancategory( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_loancategory' + id)
            return new Promise((resolve, reject) => {
            axios.post("/setup/loancategories/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setloancategories', response.data.data)
                toast.success("loancategory Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_loancategory_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_loancategory_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/setup/loancategories/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setloancategories', response.data.data)
                        if (data.status == 1) {
                            toast.success("loancategory Activated Successfully")
                        } else {
                            toast.success("loancategory Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                comp_play_error_file(error.response.data.msg);
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                                comp_play_error_file(error.response.data.message);
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_loancategory( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_loancategory' + id)
            return new Promise((resolve, reject) => {
            axios.post("/setup/loancategories/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setloancategories', response.data.data)
                comp_play_delete_file('loancategory Deleted Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


    }


    
}