export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        subcounties :'', 
        subcounty :'', 
    },
    
    getters : {
        subcounties: (state )=> state.subcounties,
        subcounty: (state )=> state.subcounty,
    },


    mutations : { 
        setsubcounties :(state,subcounties ) => (state.subcounties = subcounties), 
        setsubcounty :(state,subcounty ) => (state.subcounty = subcounty), 
    },

    actions : {

        async create_subcounty( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_subcounty')
            return new Promise((resolve, reject) => {
            axios.post("/setup/subcounties/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubcounties', response.data.data)
                toast.success("subcounty Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },


        async get_subcounties( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_subcounties')
            return new Promise((resolve, reject) => {
            axios.post("/setup/subcounties?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubcounties', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_subcounty( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_subcounty' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/setup/updates/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubcounty', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_subcounty( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_subcounty' + id)
            return new Promise((resolve, reject) => {
            axios.post("/setup/subcounties/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubcounties', response.data.data)
                toast.success("subcounty Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_subcounty_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_subcounty_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/setup/subcounties/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setsubcounties', response.data.data)
                        if (data.status == 1) {
                            toast.success("subcounty Activated Successfully")
                        } else {
                            toast.success("subcounty Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                comp_play_error_file(error.response.data.msg);
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                                comp_play_error_file(error.response.data.message);
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_subcounty( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_subcounty' + id)
            return new Promise((resolve, reject) => {
            axios.post("/setup/subcounties/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubcounties', response.data.data)
                comp_play_delete_file('subcounty Deleted Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


    }


    
}