export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from '../modules/utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        user_loans :'', 
        user_loan :'', 
    },
    
    getters : {
        user_loans: (state )=> state.user_loans,
        user_loan: (state )=> state.user_loan,
    },


    mutations : { 
        setuser_loans :(state,user_loans ) => (state.user_loans = user_loans), 
        setuser_loan :(state,user_loan ) => (state.user_loan = user_loan), 
    },

    actions : {


        async get_user_loans( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_user_loans')
            return new Promise((resolve, reject) => {
            axios.post("/open_user/loans?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setuser_loans', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async search_user_loans( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'search_user_loans')
            return new Promise((resolve, reject) => {
            axios.post("/open_user/loans?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setuser_loans', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_user_loan( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_user_loan')
            return new Promise((resolve, reject) => {
            axios.post("/open_user/loans/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setuser_loan', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


    }


    
}