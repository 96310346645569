import store from '@/store'
const adminguard = (to, from, next) => {
  const user = store.getters.authuser;
  const userId = user ? user.id : null;
  const isLoggedIn = userId && localStorage.getItem('umraloanmanagementsystem' + userId) !== null;

  console.log('Navigating from:', from.name, 'to:', to.name);
  console.log('User ID:', userId);
  console.log('Is Logged In:', isLoggedIn);
  console.log('User:', user);

  if (isLoggedIn) {
    if (user.userhospital != '') {
      console.log('User has a hospital setup. Proceeding to next route.');
      return next();
    } else {
      console.log('User does not have a hospital setup.');
      if (to.name !== 'setup_hospital') {
        console.log('Redirecting to setup_hospital route.');
        return next({ name: 'setup_hospital' });
      } else {
        console.log('Already on setup_hospital route. Proceeding.');
        return next(); 
      }
    }
  } else {
    console.log('User is not logged in.');
    if (to.name !== 'login') {
      console.log('Redirecting to login route.');
      // return next({ name: 'login' });
    } else {
      console.log('Already on login route. Proceeding.');
      // return next(); 
    }
  }
};

let routes = [
    { 
        path: "/admin",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/main.vue"),
        title:'',

          children: [

            {
              path: "",
              name: "admin_welcome",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/dash.vue"),
              meta: {
                requiresAuth: true,
              title: ' Dashboard'
              }
            },

            {
              path: "loans",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/loans/main.vue"),
        
              children:[
                {
                  path: "",
                  name: "loans_dashboard",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/loans/loans_dash.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Loans Dashboard'
                  }
                },

                {
                  path: "list",
                  name: "loans_list",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/loans/loans_list.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Loans Inventory'
                  }
                },

                {
                  path: ":id",
                  name: "loans_details",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/loans/loan_details.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Loan Details Dashboard'
                  }
                },
              ]
            },


            {
              path: "institutions",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/institutions/main.vue"),
        
              children:[
                {
                  path: "",
                  name: "institutions_dashboard",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/institutions/institutions_dash.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Institutions Dashboard'
                  }
                },
                {
                  path: "create",
                  name: "create_institution",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/institutions/create_institution.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Institutions Inventory'
                  }
                },
                {
                  path: "list",
                  name: "institutions_list",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/institutions/institutions_list.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Institutions Inventory'
                  }
                },

                {
                  path: ":id",
                  name: "institutions_details",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/institutions/institution_details.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Institution Details Dashboard'
                  }
                },
              ]
            },
            


            {
              path: "setup/districts",
              name: "manage_districts",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/setup/districts.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Districts'
              }
            },

            {
              path: "setup/insititutions-categories",
              name: "manage_insititutions_categories",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/setup/insititutions_categories.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Insititution Categories'
              }
            },
            {
              path: "setup/loan-categories",
              name: "manage_loan_categories",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/setup/loan_categories.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Loan Categories'
              }
            },
            {
              path: "setup/sub-counties",
              name: "manage_sub_counties",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/setup/sub_counties.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Sub Counties'
              }
            },


            
        ]
    }
]
export default routes;