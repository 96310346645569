export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from '../modules/utils';
import { useToast } from 'vue-toastification'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
const toast = useToast()

export default {
    state : {
        user_institution_categories :'', 
        user_loan_categories :'',
        user_districts :'', 
        deviceDetails :'', 
    },
    
    getters : {
        user_institution_categories: (state )=> state.user_institution_categories,
        user_loan_categories: (state )=> state.user_loan_categories,
        user_districts: (state )=> state.user_districts,
        deviceDetails: (state )=> state.deviceDetails,
    },


    mutations : { 
        setuser_institution_categories :(state,user_institution_categories ) => (state.user_institution_categories = user_institution_categories), 
        setuser_loan_categories :(state,user_loan_categories ) => (state.user_loan_categories = user_loan_categories), 
        setuser_districts :(state,user_districts ) => (state.user_districts = user_districts), 
        setdeviceDetails :(state,deviceDetails ) => (state.deviceDetails = deviceDetails),
    },

    actions : {

        async get_user_device_details({commit}){
            try {
                const fp = await FingerprintJS.load()
                const result = await fp.get()
                console.log(result.visitorId);
                commit('setdeviceDetails', result.visitorId)
              } catch (error) {
                console.error('Error getting device details:', error)
              }
        },


        async get_user_districts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            return new Promise((resolve, reject) => {
            axios.post("/open_user/global/districts?page=" + page, data)
            .then(response => {	
                if(response.data){
                commit('setuser_districts', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_user_institution_categories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            return new Promise((resolve, reject) => {
            axios.post("/open_user/global/institution_categories?page=" + page, data)
            .then(response => {	
                if(response.data){
                commit('setuser_institution_categories', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_user_loan_categories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            return new Promise((resolve, reject) => {
            axios.post("/open_user/global/loan_categories?page=" + page, data)
            .then(response => {	
                if(response.data){
                commit('setuser_loan_categories', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


 
    }


    
}