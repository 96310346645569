let routes = [
    { 
        path: "/",
        props: true,
        component: () => import("@/views/user/main.vue"),
        title:'',

          children: [

            {
              path: "/",
              name: "welcome",
              props:true,
              component: () => import("@/views/user/dash.vue"),
              meta: {
              title: ' Dashboard'
              }
            },

            {
              path: "/loan-products",
              name: "loan_products",
              props:true,
              component: () => import("@/views/user/loans/loans.vue"),
              meta: {
              title: ' Dashboard'
              }
            },

            {
              path: "/loan-products/:id",
              name: "loan_product_details",
              props:true,
              component: () => import("@/views/user/loans/loan_details.vue"),
              meta: {
              title: ' Dashboard'
              }
            },

             {
              path: "/loan-products/compare/:id",
              name: "loan_product_compare",
              props:true,
              component: () => import("@/views/user/loans/compare_loans.vue"),
              meta: {
              title: ' Dashboard'
              }
            },

            {
              path: "/institutions",
              name: "institutions",
              props:true,
              component: () => import("@/views/user/institutions/institutions.vue"),
              meta: {
              title: ' Dashboard'
              }
            },

            {
              path: "/institutions/:id/:title",
              name: "user_institutions_details",
              props:true,
              component: () => import("@/views/user/institutions/institution_details.vue"),
              meta: {
              title: ' Dashboard'
              }
            },

            {
              path: "/loan_caclulator",
              name: "loan_caclulator",
              props:true,
              component: () => import("@/views/user/loan_caclulator.vue"),
              meta: {
              title: ' Calculate Instrest on Loans'
              }
            },
        ]
    }
]
export default routes;