export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from '../modules/utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        user_comparisons :'', 
        user_comparison :'', 
    },
    
    getters : {
        user_comparisons: (state )=> state.user_comparisons,
        user_comparison: (state )=> state.user_comparison,
    },


    mutations : { 
        setuser_comparisons :(state,user_comparisons ) => (state.user_comparisons = user_comparisons), 
        setuser_comparison :(state,user_comparison ) => (state.user_comparison = user_comparison), 
    },

    actions : {


        async create_user_comparison( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_user_comparison')
            return new Promise((resolve, reject) => {
            axios.post("/open_user/loans/compare", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setuser_comparison', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_user_comparison( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_user_comparison')
            return new Promise((resolve, reject) => {
            axios.post("/open_user/loans/compare/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setuser_comparison', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


    }


    
}